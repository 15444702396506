import request from "@/utils/request";
import qs from "querystring";

// 注册-注册提交以及获取验证码
export function account(data) {
  // const params = qs.stringify({
  //   ...data
  // })
  // console.log(params)
  return request.post("/account", data);
}

export function getPhoneCode(phone, type) {
  // 发送类型 （SIGNIN—注册，FORGET—忘记密码，RESET—重置密码，BIND—微信绑定）
  return request.post(`/captcha/${type}/${phone}`);
}
// 登录获取验证码接口
export function getLoginCaptcha(phone, type) {
  // 发送类型 LOGIN 登录
  return request.post(`/captcha/${type}/${phone}`);
}
// 登录
export function login(data, auth) {
  return request.post("/oauth/token", data, auth);
}

// 常见问题
export function queryFrequentlyQuestionList(phone) {
  return request.get(`/order/queryFrequentlyQuestionList`);
}

// 忘记密码
export function respas(data) {
  return request.put("/account", data);
}

// 修改密码
export function newoldpas(data) {
  return request.patch("/account", data);
}

// 查询系统银行卡
export function banklist() {
  return request.get("/sys/bank");
}

export function getCard() {
  return request.get("/card");
}

// 获取当前用户的客服
export function queryContactInfo() {
  return request.get("/sys/queryContactInfo");
}

// 获取用户消息
export function gettrade() {
  return request.get("/user/msg");
}

// 获取用户消息-详情页面
export function msgdetails(msgId) {
  return request.get(`/user/msg/${msgId}`);
}

//  用户获取自己的地址
export function getAddress() {
  return request.get("/address ");
}

//  用户添加自己的地址
export function addAddress(data) {
  return request.post("/address", data);
}

//  用户删除自己的地址
export function deleteAddress(addrId) {
  return request.delete(`/address/${addrId}`);
}

//  用户修改自己的地址
export function edditAddress(addrId, data) {
  return request.patch(`/address/${addrId}`, data);
}

//  卖出/违约结算记录
export function queryJieSuanOrderList(data) {
  // orderType结算类型：0 卖出结算 1压盘结算
  return request.post(`/order/queryJieSuanOrderList`, data);
}

//  卖出结算-电子单据
export function queryJieSuanOrderElecSaleNew(data) {
  return request.post(`/order/queryJieSuanOrderElecSaleNew`, data);
}

//  卖出结算详情
export function queryJieSuanOrderDetail(data) {
  // orderType结算类型：0 卖出结算 1压盘结算
  // orderId  订单id
  return request.post(`/order/queryJieSuanOrderDetail`, data);
}
//  卖出结算详情
export function queryPriceDetails(data) {
  return request.post(`/order/queryJieSuanOrderDetailSaleNew`, data);
}


//  卖出结算确认
export function sureJieSuanOrder(data) {
  // orderId  订单id
  return request.post(`/order/sureJieSuanOrder`, data);
}

// 未处理订单数量
export function noProcessing(){
  return request.get('/statement/pending')
}
//  查询保险列表
export function queryInsuranceList() {
  return request.get(`/order/queryInsuranceList`);
}

//  查询我的保单详情
export function queryInsuranceDetail(orderNo) {
  return request.get(`/order/queryInsuranceDetail?orderNo=${orderNo}`);
}

//  用户物流请求列表
export function myexpress(type) {
  return request.get(`/order/express?status=${type}`);
}

//  金价实时更新
export function queryCurrentPriceLineChart(type) {
  return request.get(`/order/queryCurrentPriceLineChart`);
}

//  金价K线图实时更新
export function queryPriceLineChartData(key) {
  // key:金属种类 黄金：huangjin9999  白银：baiyin9999  钯金：bajin9996  铂金：bojin9996
  return request.get(`/order/queryPriceLineChartData?key=${key}`);
}

//  个人中心待确认金额
export function queryJieSureWattingMoney(key) {
  return request.get(`/order/queryJieSureWattingMoney`);
}

//  获取ws的Url
export function settingSys(key) {
  return request.get(`/setting/sys`);
}

// 用户进行身份认证
export function authUser(data) {
  return request.post(`/user/authUser`, data);
}

export function getAuthUser() {
  return request.get(`/user/authUser`);
}

export function getUserAccount() {
  return request.get(`/card`);
}


// 上传文件
// export function fileup (data) {
//   return request.post(`/api/file?method=POST&contentType=multipart/form-data`,data)
// }

// 获取文件上传地址
export function apiFile(data) {
  return request.post(`/file?size=1`,data);
}

// 获取文件上传地址
export function getApiFile(size,data) {
  return request.post(`/file?size=${size}`,data);
}

// 物流叫快递申请
export function express(data) {
  return request.post(`/order/express`, data);
}

// 物流详情
export function expressdetails(orderExpressNo) {
  return request.get(`/order/express/${orderExpressNo}`);
}
// 取消物流
export function cancelExpress(orderExpressNo) {
  return request.delete(`order/express/${orderExpressNo}/status`);
}
// 删除物流
export function deleteExpress(orderExpressNo) {
  return request.delete(`order/express/${orderExpressNo}`);
}

// 上传文件地址
export function updaFileapi(url, data) {
  return request.put(url, data);
}

//  用户创建买金订单
export function buygold(data) {
  return request.post("/order/buy", data);
} 
//  获取买料订单信息
export function getbuygold(data) {
  return request.get(`/order/buy/${data}`);
} 
//  获取卖料订单信息
export function getsellgold(data) {
  return request.get(`/order/sell/${data}`);
} 


//  用户创建卖出订单
export function sellgold(data) {
  return request.post("/order/sell", data);
}

// 获取用户信息
export function getuser() {
  return request.get("/user");
}

// 获取用户信息
export function getundeliveredcount() {
  return request.get("/order/sell/undelivered/count");
}
// 新增投保信息
export function addInsurance(data) {
  return request.post("/order/addInsurance", data);
}

// 获取平台默认的收件人地址id
export function querySysAddressList() {
  return request.get("/sys/querySysAddressList");
}

// 定价记录列表
export function mortgageApi(status, page) {
  const page_ = page || 1;
  // status => ALL 全部数据  SUSPENSE 待审核 UNDELIVERED 待发货  TRANSIT 运送中  FAIL 未通过 CANCEL 已取消  DONE 已完成
  // return request.get(`/api/order/mortgage/list?page=${page_}&type=1&status=${status}`)
  return request.get(`/order/sell/list?page=${page}&status=${status}`);
}

// 押金记录列表
export function depositAll() {

  return request.get(`/deposit/all`);
}

// 提交押金记录
export function returnDeposit(data) {

  return request.post(`/deposit`,data);
}
// 未处理买料列表
export function getBuyDeposit() {

  return request.get(`/statement/pending/buy`);
}

// 快递到家/自取
export function  getOrderReclaiming(data){
  return request.post(`/order/buy/fetch`,data)
}
// 买料结算单详情
export function  getBuyOrderList(openId){
  return request.get(`/statement/buy/${openId}`)
}
// 上传付款信息
export function  upLodaInfo(openId,data){
  return request.put(`/statement/buy/${openId}/payed`,data)
}
// 提料预付上传付款信息
export function  upLodaMaterialInfo(openId,data){
  return request.put(`/statement/material/${openId}/payed`,data)
}
// 新闻列表
export function  getNewsList(data){
  return request.get(`/news`,data)
}
// 获取用户协议文本
export function  getAgreement(type){
  return request.get(`/sys/user/agreement/${type}`)
}
// 弹窗接口  获取线上回收流程图 -成色表
export function  getFlowChart(platform){
  return request.get(`/dialog/${platform}`)
}
// 存料记录接口
export function getStockList (data){
  return request.get(`/order/mortgage/manage`,data)
}
// 提料记录接口
export function getMaterialList (data){
  return request.get(`/order/material/manage`,data)
}
// 获取存料结算列表
export function getKeepJieSuanList (){
  return request.get(`/statement/mortgage/all`)
}
// 获取提料结算列表
export function getKeepMaterialJieSuanList (){
  return request.get(`/statement/material/all`)
}
// 获取存料结算单
export function getOrderStockData (data){
  return request.post(`/order/openMortgage/detail`,data)
}
// 获取提料结算单
export function getOrderMaterialData (data){
  return request.post(`/order/openMaterial/detail`,data)
}
// 获取存料数据
export function getJieSuanStockData (data){
  return request.post(`order/queryJieSuanOrderDetailYaPanYuFuNew`,data)
}
// 获取提料预付数据
export function getJieSuanMaterialData (data){
  return request.post(`order/queryJieSuanOrderDetailTiLiaoYuFuNew`,data)
}
// 获取存料数据
export function getStockData (data){
  return request.post(`order/queryJieSuanOrderDetailYaPanNew`,data)
}
// 存料订单结算
export function StockOrderJieSuan(data) {
  return request.delete(`/order/mortgage/${data.openId}/${data.time}`);
}
// 提料订单结算
export function MaterialOrderJieSuan(data) {
  return request.delete(`/order/material/${data.openId}/${data.time}`);
}

// 存料预付电子单据
export function StockOrderYuFUDianZi(data){
  return request.post(`order/queryJieSuanOrderElecYaPanYuFuNew`, data);
}
// 存料结算电子单据
export function StockOrderStockDianZi(data){
  return request.post(`order/queryJieSuanOrderElecYaPanNew`, data);
}
// 存料预付电子单据
export function MaterialOrderYuFUDianZi(data){
  return request.post(`order/queryJieSuanOrderElecTiLiaoYuFuNew`, data);
}
// 存料结算电子单据
export function MaterialOrderMaterialDianZi(data){
  return request.post(`order/queryJieSuanOrderElecTiLiaoNew`, data);
}
// 卖料定价订单列表
export function getSellingPriceList(data) {
  return request.get(`/order/sell/list`,data);
}
// 买料定价订单列表
export function getBuyOrderPriceList(data) {
  return request.get(`/order/buy/list`,data);
}
// 买料结算订单列表
export function getBuyOrderJieSuanList(data) {
  return request.get(`/statement/buy`,data);
}
// 人脸认证接口
export function getFaceInfo(data) {
  return request.post(`/face/faceVerify`,data);
}
// 违约结算-违约说明内容接口
export function getDefaultExplain(data) {
  return request.get(`/content`,data);
}

// 查可用定金接口
export function getAvailableMoney() {
  return request.get(`/earnestMoney/getFreeEarnestMoney`);
}

// 查价格异常拦截设置接口
export function getOrderIntercept() {
  return request.get(`/setting/orderIntercept`);
}

// 提料活动配置设置
export function getOrderBuy() {
  return request.get(`/setting/orderBuy`);
}
// 提料活动开关设置
export function getOrderBuyOpen() {
  return request.get(`/setting/orderBuyOpen`);
}

// 文件上传接口
export function upFile(data) {
  return request.post(`/file`,data);
}

// 卖料下单接口
export function SellOrder(data) {
  return request.post(`/order/sell`,data);
}
// 关于我们图片接口
export function getAboutPhoto() {
  return request.get(`/dialog`);
}
// 违约结算列表接口
export function getViolateData(data) {
  return request.get(`/violate/list`,data);
}
export function getUserOrder(data) {
  return request.post(`/user/getUserOrder`, data);
}

// 获取押金调整设置接口
export function getDepositRepair(){
  return request.get(`/setting/deposite`);
}
// 获取补退押金政策接口
export function getDepositRatio(){
  return request.get(`/setting/depositeRatio`);
}
// 押金检查接口
export function getEarnestMoney(orderId) {
  return request.get(`/earnestMoney/${orderId}/check`);
}


// 确认补押金接口
export function confirmRepairDeposit(data) {
  return request.put(`/earnestMoney/confirm`,data);
}
// 确认违约结算接口
export function confirmViolateJieSuan(data) {
  return request.post(`/violate/confirm`,data);
}
// 查银行卡列表接口
export function getBankList() {
  return request.get(`/card`);
}
// 新增银行卡接口
export function addBank(data) {
  return request.post(`/card`,data);
}
// 删除银行卡接口
export function delBank(cardId) {
  return request.delete(`/card/${cardId}`);
}
// 修改银行卡接口
export function EditBank(data,cardId) {
  return request.put(`/card/${cardId}`,data);
}
// 查违约结算单接口
export function violateJieSuanList() {
  return request.get(`/statement/VIOLATE`,);
}
// 上传签名接口
export function getUserSign(data) {
  return request.put(`/user/setUserSign`,data);
}

// 上传营业执照接口
export function getUserLicense(data) {
  return request.put(`/user/setUserLicense`,data);
}

// 订阅价格提醒接口
export function alertPrice(data) {
  return request.post(`/alert/price`,data);
}

// 协议API
export function agreementApi(type) {
  // 用户协议类型;注册协议-REGISTER,
  // 货运基本条例-TRANSPORT,
  // 附加航空-EXTRA_AIR_INSURANCE,
  // 附加邮保险-EXTRA_EXPRESS_INSURANCE ,用户隐私政策-PRIVACY_POLICY
  return request.get(`/sys/user/agreement/${type}`);
}

// http://www.beijingzxj.com/api/order/queryCurrentPriceLineChart

//about zhongxinjin
export function about() {
  return request.get("/sys/about");
}
// 买料定价订单列表
export function getPriceAlertList(data) {
  return request.get(`/alert/price`,data);
}
// 获取微信openId
export function getWeixinOpenId(data) {
  return request.get(`/weixin/${data}`);
}
